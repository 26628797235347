/* YourComponent.css */

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-blink {
    animation: blink 1s infinite;
}