@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-error: #F2385F !important;
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-Regular.ttf") type('truetype');
}

@font-face {
  font-family: "Jameel";
  src: url("./assets/fonts/Jameel Noori Nastaleeq Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-Bold.ttf") type('truetype');
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-Light.ttf") type('truetype');
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-LightItalic.ttf") type('truetype');
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-Thin.ttf") type('truetype');
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-ThinItalic.ttf") type('truetype');
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-Italic.ttf") type('truetype');
}

@font-face {
  font-family: "Poppins";
  src: font-url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") type('truetype');
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
}

.checkmark-container {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
  padding-left: 35px;

}

/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 5px;
  border: 1px solid #F2385F;
}
.checkmark-radio {
  border-radius: 50px;
}
/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
  background-color: #F2385F;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
  left: 30%;
  top: 50%;
  width: 5px;
  height: 9px;
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  -ms-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
}

.switch_checkbox_wrapper {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
}
/* Hide default HTML checkbox */
.switch_checkbox_wrapper input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.switch_checkbox_wrapper .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2A2D43;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50px;
}
.switch_checkbox_wrapper .slider:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  left: 8%;
  bottom: 20%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50px;
}
.switch_checkbox_wrapper input:checked + .slider {
  background-color: #F2385F;
}
.switch_checkbox_wrapper input:focus + .slider {
  box-shadow: 0 0 1px #F2385F;
}
.switch_checkbox_wrapper input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* .font-noori {
  font-size: 16px;
} */