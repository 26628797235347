.react-calendar__tile--active {
  background: #d9d9d9;
  color: #b1b1b1;
}

/* Range colors */
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: #f2385f;
  border-radius: 50%;
  color: white;
}
/* Range colors, i dont like the important */
.react-calendar__tile--rangeStart:hover,
.react-calendar__tile--rangeEnd:hover {
  background: #f2385f !important;
  border-radius: 50%;
  color: white !important;
}

/* Range Hover and focus colors */
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #d9d9d9;
  color: #b1b1b1;
}
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active:enabled:hover {
  background: #d9d9d9;
  color: #b1b1b1;
}

/* Month button */
.react-calendar__navigation button:disabled {
  background-color: transparent;
}

/* Weekly dayws */
.react-calendar__month-view__weekdays__weekday {
  color: #b5bec6;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover {
  background-color: rgb(102, 183, 255);
  color: white;
}
