@media print {

    /* Set landscape orientation */
    @page {
        size: A4 portrait;
    }

    /* Set A4 paper size explicitly (in case it's not the default) */
    /* body {
    width: 210mm;
    height: 297mm;
  } */

    /* Set default scale */
    /* body {
    transform: scale(0.85);  */
    /* Adjust the scale factor as needed */
    /* transform-origin: top left;
  } */
}